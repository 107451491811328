.salary-slip-parent 
{
    --grey: #cad0d9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--unit);
    padding-top: var(--unit);
    
    .salary-slip-container
    {
        // border: 1px solid red;
        background: white;
        
        .main-contaniner {
            height: auto;
            width: 100%;
            // border: 1px solid rgb(189, 189, 189);
            border-radius: 10px;
            padding: 10px 40px;
        
            .header {
                border-bottom: 1px solid var(--grey);
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                
        
                .logo {
                    img {
                        height: 60px;
                    }
                }
        
                .dates {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
        
            .section2 {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                padding: 30px 0px;
                display: flex;
        
                .left {
                    display: flex;
                    flex-direction: column;
                    align-self: flex-start;


                    p{
                        padding: 5px 20px;
                    }
        
                    table {
                        border-collapse: collapse;
        
                        td {
                            padding: 5px 20px;
                        }
        
                        tr td:nth-child(2) {
                            padding: 5px 1px;
                        }
                    }
        
                }
        
                .right {
                    border: 2px solid var(--grey);
                    border-radius: 10px;
                    overflow: hidden;
                    width: 250px;
                    display: flex;
                    flex-direction: column;
        
                    .top {
        
                        padding: 15px 15px 15px 23px;
                        background: var(--accent);
        
                        p:nth-child(1) {
                            font-weight: 600;
                            font-size: 160%;
                            position: relative;
        
        
                            &::before {
                                display: block;
                                content: "";
                                height: 30px;
                                border-radius: 10px 0px 0px 10px;
                                width: 3px;
                                background: var(--green);
                                position: absolute;
                                left: -8px;
                            }
                        }
        
                        p:nth-child(2) {
                            color: var(--green);
                            font-size: 12px;
                       
                        }
                    }
        
                    .bottom {
                        border-top: 3px dotted var(--grey);
                        font-size: 12px;
                        width: 80%;
                        margin: auto;
                        padding: 15px 0px;
        
                        table {
                            width: 100%;
        
                            tr {
                                width: 100%;
        
                                td {
        
                                    padding: 5px;
                                }
        
                                td:nth-child(1) {
                                    width: 55%;
                                }
        
                                td:nth-child(2) {
                                    width: 1%;
                                    padding: 5px 0px;
                                }
        
                                td:nth-child(3) {
                                    width: 45%;
                                    padding: 5px 2px;
                                    font-weight: 700;
                                }
        
        
                            }
                        }
        
                    }
                }
        
        
        
        
            }
        
            .section3 {
                padding: 30px 10px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                border: 2px solid var(--grey);
                border-radius: 10px;
                overflow: hidden;
                margin-bottom: 30px;
        
                .main-table {
                    width: 48%;
                    border-collapse: collapse;
                    // background: red;
                    &.table-right{
        
                        tr:nth-child(8),tr:nth-child(8){
                            opacity: 0;
                            pointer-events: none;
                            user-select: none;
        
                        }
        
        
        
        
                    }
        
                    tr:last-child {
                        background: rgb(240, 239, 239);
                        margin: 30px;
                        border: 1px solid black;
                        // background: red;
                     
                    }
        
                    th {
        
                        border-bottom: 1px dotted var(--grey);
                        padding: 5px 10px !important;
                        text-align: left;
                        // background: red;
                        &:nth-child(2) {
                            text-align: right;
                        }
        
                    }
        
        
                    td {
                        padding: 5px 10px;
                        text-align: left;
        
                        &:nth-child(2) {
                            text-align: right;
                        }
                    }
                }
            }
        
        
            .section4 {
                margin-top: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 2px solid var(--grey);
                border-radius: 10px;
                overflow: hidden;
                height: fit-content;
        
                .left {
                    padding: 15px 20px;
        
                    p {
                        font-size: 12px;
                    }
                }
        
                .right {
                    background: var(--accent);
                    height: 100%;
                    padding: 20px 20px;
                }
            }
        
            .section5 {
                display: flex;
        
                p {
                    font-size: 12px;
                }
        
                justify-content: flex-end;
            }
            .section6 {
                display: flex;
                margin-top: 30px;
        
                p {
                    font-size: 12px;
                }
        
                justify-content: flex-start;
            }
        
        }
        
        
        .line{
            // height: 1px ;
            // background: red;
            width: 100%;
        }
    }
}

.words-conversion 
{
    text-transform: capitalize;
}














