.profile-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--grey);
  padding: 1.5rem;
}

.profile-container {
  background-color: white;
  width: 100%;
  max-width: 64rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.user-details,
.bank-details,
.employment-details {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.user-details-left {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
  }
}

.user-details-right {
  width: 100%;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #2d3748;
    }

    .btn {
      padding: 0.5rem 1rem;
      background-color: var(--accent);
      color: white;
      border-radius: calc(var(--unit)/4);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .info-details {
      display: flex;
      justify-content: space-between;
      color: #4a5568;

      .font-medium {
        font-weight: 500;
      }
    }
  }
}

.bank-details .heading,
.employment-details .heading {
  margin-bottom: 1.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
  }
}

.bank-details .info,
.employment-details .info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .info-details {
    display: flex;
    justify-content: space-between;
    color: #4a5568;

    .font-medium {
      font-weight: 500;
    }
  }
}

@media (min-width: 768px) {
  .user-details-left {
    width: 25%;
    margin-bottom: 0;
  }

  .user-details-right {
    width: 75%;
    padding-left: 2rem;
    align-items: flex-start;
  }

  .user-details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}


@media (max-width: 600px )
{
  .profile-parent 
  {
    padding: calc(var(--unit)) 0px;
  }
  .profile-container 
  {
    border-radius: 0px;
    padding: 10px 15px;
  }
  .heading 
  {
   h1 
   {
    font-size: calc(var(--unit) * 1.2) !important;
   }
  }
}