.offer-letter-parent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--unit);
  padding-top: var(--unit);

  .offer-letter-container {
    // border: 1px solid red;
    background: white;
    overflow: hidden;
    position: relative;
  }
}
.bold {
  font-weight: 600;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.justify 
{
  text-align: justify;
}
.gap {
  height: 10px;
  width: 100%;
  // background: red;
}

.underline {
  text-decoration: underline;
}

.pageheader {
  position: absolute;
  right: 30px;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .logo {
    width: 100px;
    height: 100px;
    // background-image: url("../assets/pan\ logo.png");
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
  }
}

.offer-letter-parent table,
.offer-letter-parent td,
.offer-letter-parent th {
  border: 1px solid;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid var(--black);

  .table-heading {
    th {
      padding: calc(var(--unit) / 4);

      &:first-child {
        width: 80px;
      }
    }
    .comp-salary {
      width: 400px;

      justify-content: center;

      text-align: center;
    }
  }

  .table-data {
    td {
      padding: calc(var(--unit) / 4);
      text-align: center;

      &:nth-child(2) {
        text-align: left;
        padding: calc(var(--unit) / 4) calc(var(--unit) / 2);
      }
      &:nth-child(3) {
        text-align: right;
        padding: calc(var(--unit) / 4) calc(var(--unit) / 2);
      }
      &:nth-child(4) {
        text-align: right;
        padding: calc(var(--unit) / 4) calc(var(--unit) / 2);
      }
    }
  }
}
