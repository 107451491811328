.login-parent {
  height: 100svh;
  background: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form {
    width: 370px;
    border-radius: calc(var(--unit) / 2);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--unit);
    padding: calc(var(--unit) * 3) 0px;
    .heading {
      font-size: calc(var(--unit) * 2);
      font-weight: 500;
    }
    .form-input {
      width: 80%;
      border: 1px solid var(--blue);
      height: calc(var(--unit) * 2.5);
      border-radius: calc(var(--unit) / 3);
      padding: calc(var(--unit) /2);
      outline: none;
      font-size: var(--unit);

    }
    .password-field {
      width: 80%;
      border: 1px solid var(--blue);
      height: calc(var(--unit) * 2.5);
      border-radius: calc(var(--unit) / 3);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      input {
        border: none;
        width: 90%;
        height: 100%;
      }
      .icon {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--unit));
        cursor: pointer;
        color: var(--accent);
      }
    }

    .login-btn 
    {
        width: 80%;
        border: none;
        height: calc(var(--unit) * 2.5);
        border-radius: calc(var(--unit) / 3);
        background: var(--accent);
        color: var(--white);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(var(--unit)/2);
        
    }
  }
}


@media only screen and (max-width: 600px) {
   .login-form 
   {
     width: 100% !important;
     height: 100% !important;
   }
   .save-credentials 
   {
     width: 100% !important;
   }
  }




  .save-credentials 
  {
    width: 80%;
    display: flex;
    gap: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.568);
  font-weight: 500;
  padding: 0px 5px;
    #save-credentials 
    {
      scale: 1.2;
      cursor: pointer;
    }
  }