.sidebar-parent {
  width: 200px;
  background: #121e31;
  height: 100svh;
  position: fixed;

  .sidebar-container {
    // border: 1px solid red;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .sidebar-links {
      display: flex;
      flex-direction: column;
      align-items: center;
    //   background: green;
      gap: 20px;
      width: 100%;
      a {
        text-decoration: none;
    
        color: rgba(255, 255, 255, 0.377);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 80%;
        padding: 10px 5px 10px 10px;

        &:hover 
        {
            background: #22284F;
            border-radius: 5px;
            color: white;
        }
      }
    }
  }
}
