.loader-parent 
{
    width: 100%;
    height: 100svh;
    position: absolute;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(17.1px);
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
}