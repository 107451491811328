.offer-parent {
  height: 100%;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .offer-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.last-edit {
  width: fit-content;
  height: 100%;
}

.last-edit-parent {
  width: fit-content !important;
  white-space: wrap !important;
  text-align: justify;
  .span-test {
    width: fit-content !important;
  }
  .mail {
    width: fit-content !important;
    display: inline !important;
    margin-right: 4px;
  }
}

.text-gap {
  width: 3px;
}

.bug-fix {
  display: inline !important;
}

.center-fix {
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;

  .center-button-box {
    position: fixed;
    bottom: 10px;
    display: flex;
    gap: 50px;
  }
}

.button-1 {
    width: 150px;
    height: 40px;
    border: none;
    font-size: 16px;
    background: var(--accent);
    border-radius: calc(var(--unit) / 4);
    color: var(--white);
    outline: none;
    cursor: pointer;
}
.button-2 {
    padding: 5px 20px !important;
    border: none;
    font-size: 16px;
    background: var(--light);
    border-radius: calc(var(--unit) / 4);
    color: var(--white);
    outline: none;
    cursor: pointer;
    width: 150px;
    opacity: 1;
}
