@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
:root {
  --accent: #0052cc;
  --blue: #05153a;
  --black: #000000;
  --grey: #d9d9d9;
  --white: #ffffff;
  --light: #6197e9;
  --test: #f07837bb;
  --unit: 16px;
}

*,
body,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.parent {
  width: 100%;
  background: var(--grey);

  .container {
    width: 60%;
    height: 100%;
  }
}
body {
  background: var(--grey);
}
form {
  width: 60%;
  // height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--unit) / 2);
  background: var(--white);
  border-radius: calc(var(--unit) / 4);
  padding: 32px 0px;
  .form-row {
    width: 90%;
    // height: calc(var(--unit) * 2.5);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--unit) / 2);
    

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      line-height: 100%;
      p {
        color: rgba(128, 128, 128, 0.719);
        font-size: 13px;
        line-height: 100%;
      }
      input,
      textarea,
      select {
        
        margin: 3px 0px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: calc(var(--unit) / 4);
        outline: none;
        border: 1px solid var(--blue);
        padding: calc(var(--unit) / 2);
        font-size: calc(var(--unit) / 1.2);
        font-weight: 500;

        height: calc(var(--unit) * 2.5);
      }
    }

    .form-heading {
      width: 100%;
      font-size: calc(var(--unit) * 1.5);
      font-weight: 500;
    }
    .form-row-left {
      width: 100%;
      height: 100%;
      display: flex;
      gap: calc(var(--unit) / 2);
    }
    .form-row-right {
      width: 100%;
      height: 100%;
      display: flex;
      gap: calc(var(--unit) / 2);
    }
  }
  .form-row-btn {
    width: 90%;
    height: calc(var(--unit) * 2.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc(var(--unit) / 2);

    .form-link {
      background: var(--light);
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(var(--unit) / 1.5) 0px;
      width: 100%;
      border-radius: calc(var(--unit) / 4);
      text-decoration: none;
      font-size: calc(var(--unit) / 1.2);
      cursor: pointer;
      border: none;
    }
    .submit-btn {
      border: none;
      padding: calc(var(--unit) / 1.5) 0px;
      width: 100%;
      background: var(--accent);
      border-radius: calc(var(--unit) / 4);
      color: var(--white);
      outline: none;
      cursor: pointer;
    }
  }
  .form-row-textarea {
    height: calc(var(--unit) * 4);
  }
}
.btn {
  border: none;
  padding: calc(var(--unit) / 2) calc(var(--unit) * 2);
  background: var(--accent);
  border-radius: calc(var(--unit) / 4);
  color: var(--white);
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.path {
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--accent);
  }
}

.loading-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {
  form {
    // width: 90% !important;
    padding: 20px 0px !important;
  }
}
@media only screen and (max-width: 800px) {
  form {
    padding: 5px 0px !important;
 
  }
}

.editable {
  // text-decoration: underline;
  // text-decoration-color: var(--accent);

  color: rgba(255, 0, 0, 0.87);
}

.label-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: calc(var(--unit) / 4);
}

// layout

.layout-parent {
  width: 100%;
  height: 100svh;
  display: flex;
  .layout-left {
    height: 100%;
    width: fit-content;
  }
  .layout-right {
    height: 100%;
    width: 100%;
    overflow-y: auto !important;
  }
}
