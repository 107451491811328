.employee-parent {
  // height: calc(100svh - calc(var(--unit) * 4));
  display: flex;
  align-items: center;
  justify-content: center;
  .employee-container {
    height: 80%;
    width: 60%;
    background: var(--white);
    border-radius: calc(var(--unit) / 4);
    padding: 0px calc(var(--unit) / 3);
    .datagrid {
      height: 85% !important;
      min-width: 100% !important;
      max-width: fit-content !important;
    }

    .header-employee {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(var(--unit) / 2) calc(var(--unit) / 3);
      outline: none;
      border: none;

      .headerTitle {
        font-size: calc(var(--unit) * 1.5);
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .employee-container {
    width: 90% !important;
  }
}
.MuiDataGrid-columnHeaderTitleContainer {
  color: var(--accent);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.MuiDataGrid-cell {
  background: var(--white);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: solid #e0e0e0;
  border-width: thin;
}

@media only screen and (max-width: 500px) {
  .header-employee {
    .headerTitle {
      font-size: calc(var(--unit) 1.2) !important;
    }
  }

  .btn {
    border: none;
    padding: calc(var(--unit) / 1.6) calc(var(--unit) / 0.8);
    font-size: calc(var(--unit) 1) !important;
  }
}

.action-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--unit));
  .action-btn {
    font-size: calc(var(--unit) * 1.5);
    border: none;
    background: none;
    &:nth-child(1) {
      color: rgba(0, 0, 253, 0.685);
      cursor: pointer;
      &:hover {
        color: rgba(0, 0, 255, 0.89);
      }
    }
    &:nth-child(2) {
      color: #f16969;
      cursor: pointer;
      &:hover {
        color: #f83434;
      }
    }
  }
}


.profile-image 
{
  border-radius: 50%;
  object-position: center center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.profile-image 
{
  width: 45px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}
.MuiDataGrid-cell 
{
  display: flex;
  align-items: center;
  justify-content: center;
}
a 
{
  display: flex;
  align-items: center;
  justify-content: center;
}