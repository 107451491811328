.create-employee-parent
{
    min-height: calc(100svh - calc(var(--unit) * 4));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    form 
    {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .create-employee-parent {
        form 
        {
            height: 90% !important;
            margin-top: 20px;
        }
      
    }
  }