.salary-parent {
  height: 100%;
  // overflow: hidden !important;
  // background: #000;
  .salary-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
