.relieving-letter-parent 
{
    // height: calc(100svh - calc(var(--unit) * 4));
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    height: auto;
    .container 
    {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.relieving-btn 
{
    position: fixed;
    bottom: 10px;
}