.navbar-parent {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .navbar-container {
    background: var(--white);
    height: calc(var(--unit) * 3) !important;
    width: 60%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: calc(var(--unit) / 3);
    padding: 0px calc(var(--unit) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      height: 100%;
      .title {
        text-decoration: none;
        font-size: calc(var(--unit) * 1.2);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        color: var(--accent);
      }
    }
    .right {
      height: 100%;
      display: flex;
      gap: calc(var(--unit));
      align-items: center;
      .link-item {
        cursor: pointer;
        text-decoration: none;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--unit) / 1.2);
        padding: 0px calc(var(--unit) / 2);
        color: var(--accent);
        font-weight: 500;
        border-radius: calc(var(--unit) / 4);
        transition: all 250ms ease-in-out;
        &.active {
          background: var(--light);
          color: var(--white);
        }
      }
      .logout-btn {
        border: none;
        background: rgb(241, 105, 105);
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .navbar-container {
    width: 90% !important;
  }
}
@media only screen and (max-width: 800px) {
  .navbar-container {
    width: 95% !important;
  }
}
@media only screen and (max-width: 600px) {
  .mobile-menu {
    display: flex !important;
  }
  .right 
  {
    display: none !important;
  }

  .menu-parent 
  {
    display: block !important;
  }
}

// .menu-parent
// {
//   border: 1px solid red;
//   height: 100%;
//   width: 200px;
// }

.mobile-menu {
  position: absolute;
  z-index: 100001;
  width: 100%;
  height: 100svh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.1px);
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  gap: calc(var(--unit));
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  .link-item {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--unit) / 1.2);
    width: 100%;
    padding: calc(var(--unit)) 0px;
    color: var(--accent);
    font-weight: 500;
    transition: all 250ms ease-in-out;

    &.active {
      background: var(--light);
      color: var(--white);
    }
  }

  .logout-btn {
    border: none;
    background: rgb(241, 105, 105);
    color: white;
    width: 100%;
  }
}

.menu-parent {
  z-index: 1000022;
display: none;
}
