.confirmation-parent {
  width: 100%;
  height: 100svh;
  position: absolute;
  backdrop-filter: blur(17.1px);
  background: rgba(26, 23, 23, 0.2);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;

  .confirmation-box {
    width: 330px;
    background: white;
    font-weight: 500;
    padding: calc(var(--unit) * 1.5);
    border-radius: calc(var(--unit) / 4);
    display: flex;
    flex-direction: column;
    gap: calc(var(--unit));
    .heading {
      font-size: calc(var(--unit) * 1);
    }
    .button-box {
      display: flex;
      align-items: center;
      gap: calc(var(--unit) / 2);
      .alert-btn {
        padding: calc(var(--unit) / 2) calc(var(--unit));
        cursor: pointer;
        border: none;
        border-radius: calc(var(--unit) / 4);
        &:nth-child(1) {
          background: rgba(255, 0, 0, 0.541);
          color: white;
        }
        &:nth-child(2) {
        //   background: rgba(0, 128, 0, 0.788);
          color: black;
        }
      }
    }
  }
}
